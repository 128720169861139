import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{ textAlign: "center" }}>
      <div
        style={{
          maxWidth: `200px`,
          marginTop: `1.45rem`,
          marginBottom: `1.45rem`,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Image />
      </div>
      <div className="card" style={{ borderRadius: "20px" }}>
        <div className="card-content">
          <h1 className="title is-1">Mokko Labs</h1>
          <p>
            Mokko Labs is a boutique design and development agency for modern
            digital experiences.
          </p>
          <p>
            For our clients, we have built design systems, mobile apps, websites
            and entire startup stacks.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
